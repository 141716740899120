<template>
  <div>
    <el-row :gutter="20">
      <el-form :model="addform" :label-position="getLable" label-width="100px" ref="addform">
        <!-- <el-col :span="12">
          <el-form-item label="预约记录ID" prop="os_id">
            <el-input v-model="addform.os_id" placeholder="请输入预约ID" clearable></el-input>
          </el-form-item>
        </el-col> --><!-- /预约记录ID -->
        <el-col :span="12">
          <el-form-item :label="$t('客户名称')" required prop="oc_id" :rules="[{ required: true, message: $t('不能为空')}]">
            <el-select v-model="addform.oc_id" @change="getInfoID(addform.oc_id)" clearable :placeholder="$t('请选择客户')">
              <el-option v-for="item in idList.ocList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /客户ID -->
        <el-col :span="12">
          <el-form-item :label="$t('服务类型')" required prop="service_type" :rules="[{ required: true, message: $t('不能为空')}]">
            <el-select v-model="addform.service_type" clearable :placeholder="$t('请选择服务类型')">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /服务类型 -->
        <el-col :span="12">
          <el-form-item :label="$t('联系人')" required prop="contacts" :rules="[{ required: true, message: $t('不能为空')}]">
            <el-input v-model="addform.contacts" :placeholder="$t('请输入联系人')" clearable></el-input>
          </el-form-item>
        </el-col><!-- /联系人 -->
        <el-col :span="12">
          <el-form-item :label="$t('联系电话')" required prop="contact_number" :rules="[{ required: true, message: $t('不能为空')}]">
            <el-input v-model="addform.contact_number" :placeholder="$t('请填写联系电话')" clearable></el-input>
          </el-form-item>
        </el-col><!-- /区域联系电话 -->
        <el-col :span="12">
          <el-form-item :label="$t('服务内容')" required prop="service_content"
            :rules="[{ required: true, message: $t('不能为空')}]">
            <el-input v-model="addform.service_content" :placeholder="$t('请输入')" clearable></el-input>
          </el-form-item>
        </el-col><!-- /服务内容 -->
        <el-col :span="12">
          <el-form-item :label="$t('站点')" prop="or_id">
            <el-select v-model="addform.or_id" @change="getOEid(addform.or_id)" clearable :placeholder="$t('请选择站点')">
              <el-option v-for="item in regionList" :key="item.id" :label="item.rname" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /区域id -->
        <el-col :span="12">
          <el-form-item :label="$t('设备id')" prop="oe_id">
            <el-select v-model="addform.oe_id" clearable :placeholder="$t('请选择')">
              <el-option v-for="item in eqList" :key="item.id" :label="item.equipment_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /设备id-->
        <el-col :span="12">
          <el-form-item :label="$t('报单时间')" prop="declaration_time" required
            :rules="[{ required: true, message: $t('不能为空')}]">
            <el-date-picker v-model="addform.declaration_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
              :placeholder="$t('选择日期时间')">
            </el-date-picker>
          </el-form-item>
        </el-col><!-- /报单时间-->
        <el-col :span="12">
          <el-form-item :label="$t('工单接收人')" prop="receive_uid">
            <el-select v-model="addform.receive_uid" clearable :placeholder="$t('请选择接收人')">
              <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /工单接收人-->
        <el-col :span="12">
          <el-form-item :label="$t('工单协助人')" prop="assist_uid">
            <el-select v-model="addform.assist_uid" clearable :placeholder="$t('请选择')">
              <el-option v-for="item in roleList" :key="item.id" :label="item.username" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /工单协助人-->
        <el-col :span="12">
          <el-form-item :label="$t('处理方式')" prop="disposition" required :rules="[{ required: true, message: $t('不能为空')}]">
            <el-select v-model="addform.disposition" clearable :placeholder="$t('请选择')">
              <el-option v-for="item in disList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /处理方式-->
        <el-col :span="12">
          <el-form-item :label="$t('质保类型')" prop="warranty_type" required :rules="[{ required: true, message: $t('不能为空')}]">
            <el-select v-model="addform.warranty_type" clearable :placeholder="$t('请选择')">
              <el-option v-for="item in warrList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col><!-- /质保类型-->
        <el-col :span="24">
          <el-form-item :label="$t('上传图片')" prop="upload_pictures">
            <el-upload action="http://cdshapi.orangeevcharge.com/webapi/file/uploadImg" :http-request="upLoadImg"
              list-type="picture-card" :file-list="addform.upload_pictures">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url">
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" :modal="false">
              <img width="100%" :src="dialogImageUrl">
            </el-dialog>
          </el-form-item>
        </el-col><!-- /上传图片-->
        <el-col :span="24">
          <el-form-item>
            <el-button @click="clear">{{$t('清空')}}</el-button>
            <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import mixin from '../../utils/mixin.js'
export default {
  name: 'subOrder',
  mixins: [mixin],
  data () {
    return {
      addform: {
        os_id: '',
        oc_id: '',
        oe_id: '',
        service_type: '',
        contact_number: '',
        service_content: '',
        contacts: '',
        or_id: '',
        declaration_time: '',
        receive_uid: '',
        assist_uid: '',
        imageUrl: [],
        disposition: '',
        warranty_type: ''
      },
      dialogVisible: false,
      disabled: false,
      dialogImageUrl: '',
      eqList: [],
      regionList: [],
      options: [{
        value: '1',
        label: this.$t('维修')
      }, {
        value: '2',
        label: this.$t('升级')
      }, {
        value: '3',
        label: this.$t('检测')
      }, {
        value: '4',
        label: this.$t('培训')
      }, {
        value: '5',
        label: this.$t('投诉')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      idList: {},
      roleList: [],
      disList: [{
        value: '1',
        label: this.$t('上门服务')
      },
      {
        value: '2',
        label: this.$t('远程解决')
      },
      {
        value: '3',
        label: this.$t('返厂维修')
      },
      {
        value: '4',
        label: this.$t('换货')
      },
      {
        value: '5',
        label: this.$t('直接处理完成')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ],
      warrList: [{
        value: '1',
        label: this.$t('保内')
      },
      {
        value: '2',
        label: this.$t('保外')
      },
      {
        value: '0',
        label: this.$t('其他')
      }
      ]
    }
  },
  created () {
    this.getRoleAll()
  },
  mounted () {
    // this.getRoleAll()
    this.$AMap()
    // console.log('2', this.$store.state)
    this.idList = this.$store.state
  },
  methods: {
    // 验证图片大小
    beforeAvatarUpload (file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        let message = this.$t('上传头像图片大小不能超过 2MB!')
        this.$message.error(message)
      }
      return isLt2M
    },
    // 查看图片
    handlePictureCardPreview (file) {
      //  console.log(file)
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 删除图片
    handleRemove (file) {
      let massage = [
        this.$t('此操作将永久删除, 是否继续?'),
        this.$t('提示'),
        this.$t('确定'),
        this.$t('取消'),
        this.$t('已取消删除')]
      this.$MessageBox.confirm(massage[0], massage[1], {
        confirmButtonText: massage[2],
        cancelButtonText: massage[3],
        type: 'warning'
      }).then(() => {
        this.$axios.post('file/delImg', {
          filename: file.url
        }).then((res) => {
          var data = res.data
          if (data.code === 0) {
            var delEle = ''
            if (this.addform.upload_pictures) {
              this.addform.upload_pictures.forEach((item, index) => {
                if (item === file) delEle = index
              })
              if (delEle === 0) this.addform.upload_pictures.splice(0, 1)
              else this.addform.upload_pictures.splice(delEle, delEle)
            }
          } else {
            this.$message.error(data.message)
          }
        }).catch((error) => { this.$message.error(error) })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: massage[4]
        })
      })
    },
    // 上传图片
    upLoadImg (file) {
      var FD = new FormData()
      FD.append('filename', file.file, file.file.name)
      this.$axios.post('file/uploadImg', FD).then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          file.file.url = data.data.url
          this.addform.upload_pictures.push(file.file)
        } else {
          this.$message.warning(data.message)
        }
      }).catch(error => this.$message.error(error))
    },
    getInfoID (ocid) {
      this.regionList = []
      this.idList.RLid.forEach(item => {
        if (item.oc_id === ocid) this.regionList.push(item)
      })
      this.addform.or_id = this.regionList[0].id
      this.getOEid(this.addform.or_id)
    },
    // 获取设备ID
    getOEid (orid) {
      // console.log('orid', orid)
      this.eqList = []
      if (typeof (orid) === 'object') { orid = orid.id }
      this.idList.ERid.forEach(item1 => {
        if (item1.or_id === orid) this.eqList.push(item1)
      })
      this.addform.oe_id = this.eqList[0]
    },
    /* 获取经纬度 */
    getCode () {
      var that = this
      this.$AMap().then((AMap) => {
        // 延迟一秒保证实例出现
        // var address = document.getElementById('address')
        var geocoder = new AMap.Geocoder()
        // console.log('geocoder', geocoder)
        geocoder.getLocation(that.addform.address || that.editform.address, function (status, result) {
          if (status === 'complete' && result.geocodes.length) {
            var lnglat = result.geocodes[0].location
            if (that.addAdmin) {
              that.addform.lng = lnglat.lng
              that.addform.lat = lnglat.lat
            } else if (that.editAdmin) {
              that.editform.lng = lnglat.lng
              that.editform.lat = lnglat.lat
            }

            // marker.setPosition(lnglat)
            // map.setFitView(marker)
          } else {
            // log.error('根据地址查询位置失败')
          }
        })

        // console.log(address.value)
      }).catch((e) => {
        console.log(e)
      })
    },
    // 添加
    addMin () {
      let params = this.$common.forData(this.addform)
      this.$axios.post('work_order/add', params).then((res) => {
        if (res.data.code === 0) {
          this.addAdmin = false
          var that = this
          // 清空表单
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.getUserList()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 清空表单
    clear () {
      var that = this
      this.$common.resetField('addform', that)
    },
    // 获取所有管理员
    getRoleAll () {
      this.$axios.post('admin/getAll').then((res) => {
        // console.log(res)
        var data = res.data
        if (data.code === 0) {
          this.roleList = data.data
        } else {
          this.$message.warning(data.message)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
  ::v-deep .el-select,
  ::v-deep .el-date-editor {
    width: 100% !important;
  }
</style>
